
import { ReactComponent as ContactSecurityImage } from 'src/media/images/contact-security.svg';
import { ReactComponent as ContactScheduleImage } from 'src/media/images/contact-schedule.svg';
import { ReactComponent as ContactSupportImage } from 'src/media/images/contact-support.svg';
import { ReactComponent as ContactEmailImage } from 'src/media/images/contact-envelop.svg';
import { ReactComponent as ContactLocationImage } from 'src/media/images/contact-location.svg';

import ClaimBookImage from 'src/media/images/claim-book-image.webp';
import { ReactComponent as ClaimBookTitleImage } from 'src/media/images/claim-book-title.svg';
import ClaimFormImage from 'src/media/images/claim-form-image.png';
import { ReactComponent as ClaimFormTitleImage } from 'src/media/images/claim-form-title.svg';

import { ReactComponent as AboutMissionIcon } from 'src/media/images/about-mission-icon.svg';
import AboutMissionImage from 'src/media/images/about-mission-image.webp';
import { ReactComponent as AboutVisionIcon } from 'src/media/images/about-vision-icon.svg';
import AboutVisionImage from 'src/media/images/about-vision-image.webp';

import RatesBackgroundImage from 'src/media/images/rates-background-image.webp';
import { ReactComponent as RatesCalculationIcon } from 'src/media/images/rates-calculation-icon.svg';
import {
	serviceConfig,
	operationConfig,
} from 'src/constants';


const phone_list = [
	'511-768 8634',
	'511-981441606',
];
const email_list = [
	'jhidalgo@multipercargo.com',
	'jorge01hidalgo@gmail.com',
];
const location = 'Callao - Perú';
const tags = {
	facebook: {href: '#'},
	instagram: {href: '#'},
	twitter: {href: '#'},
	youtube: {href: '#'},
	phone: {
		label: phone_list[0],
		href: `tel:${phone_list[0]}`,
	},
	email: {
		label: email_list[0],
		href: `mailto:${email_list[0]}`,
	}
};

const footer = {
	labels: [
		{ label:'Ubicación', value: location, },
		{ label:'Email', value: email_list.join(' / '), },
		{ label:'Teléfono', value: phone_list.join(' / '), },
	],
	social: {
		facebook: tags.facebook,
		instagram: tags.instagram,
		twitter: tags.twitter,
		youtube: tags.youtube,
	},
	copyright: 'Copyright © 2023 MULTIPERCARGO | Todos los Derechos Reservados',
};

const contact = {
	labels: [
		{ 
		 image: { svg: ContactSecurityImage, },
		 label: 'Seguridad', value: 'En todos tus envíos',
		},
		{ 
		 image: { svg: ContactScheduleImage, },
		 label: 'Horarios', value: 'Lunes a Sábado',
		},
		{ 
		 image: { svg: ContactSupportImage, },
		 label: 'Soporte 24/7', value: phone_list.join(' / '),
		},
		{ 
		 image: { svg: ContactEmailImage, },
		 label: 'Email', value: email_list.join(' / '),
		},
		{ 
		 image: { svg: ContactLocationImage, },
		 label: 'Ubicación', value: location,
		},
	],
	form: {
		value: 'Envíanos tus consultas para obtener más información de nuestros servicios. Te atenderemos a la brevedad posible.'
	}
};

const about = {
	mission: {
		image: {src: AboutMissionImage, },
		icon: { svg: AboutMissionIcon, },
		title: 'MISIÓN',
		value: 'Somos una empresa dedicada al servicio logístico, brindando garantía, rapidez y responsabilidad, ofreciendo las mejores soluciones personalizadas para satisfacer todas las necesidades y cumplir las expectativas de nuestros clientes. Trabajando siempre con el deseo de construir relaciones a largo plazo, a través de nuestras políticas de servicio al cliente y precios competitivos.',
	},
	vision: {
		image: {src: AboutVisionImage, },
		icon: { svg: AboutVisionIcon, },
		title: 'VISIÓN',
		value: 'Nos hemos propuesto ser líderes en cargas Aéreas, Marítimas, Terrestres en el ámbito del mercado internacional, cubriendo las exigencias y expectativas de nuestros clientes.',
	}
};

const rates = {
	image: {
		src: RatesBackgroundImage,
	},
	download: {
		title: 'TARIFA',
		image: { svg: RatesCalculationIcon,},
		value: 'Descarga nuestro tarifario y encuentra los precios más cómodos para tus envíos.',
	}
};

export const homeSettings = {
	tags: tags,
	about: about,
	rates: rates,
	contact: contact,
	footer: footer,
	claim: {
		book: {
			title: {
				svg: ClaimBookTitleImage,
			},
			image: {src: ClaimBookImage, title: '', },
		},
		form: {
			image: { src: ClaimFormImage, alt: '', },
			title: { svg: ClaimFormTitleImage, },
		},
	},
	services: serviceConfig,
	operations: operationConfig,
};

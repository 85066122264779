
import { ReactComponent as OperationsProformIcon } from 'src/media/images/operations-proform-icon.svg';
import { ReactComponent as OperationsTrackingIcon } from 'src/media/images/operations-tracking-icon.svg';

export const operationConfig = {
	items: [
		{
			path: '/operations/proform',
			name: 'proform',
			description: 'Cotizar Mi Envío',
			image: {svg: OperationsProformIcon, },
			body: {
				image: {
					src: null,
					alt: '',
				}
			},
		},
		{
			path: '/operations/tracking',
			name: 'tracking',
			description: 'Tracking',
			image: {svg: OperationsTrackingIcon, },
			body: {
				image: {
					src: null,
					alt: '',
				}
			},
		}
	],
	list: {
		regimen: [
			{ value: '10', label: 'IMPORTACION DEFINITIVA'},
			{ value: '41', label: 'EXPORTACION DEFINITIVA-DUE'},
		],
		via: [
			{ value: '1', label: 'MARITIMO', },
			{ value: '4', label: 'AEREA', },
			{ value: '5', label: 'COURIER', },
			{ value: '7', label: 'CARRETERA/TERRESTRE', },
		],
		tipoCarga: [
			{value: '1', label:'Carga general',},
			{value: '2', label:'Carga a granel',},
			{value: '3', label:'Cargas pequeñas',},
			{value: '4', label:'Cargas medianas',},
			{value: '5', label:'Cargas grandes o paletizadas',},
			{value: '6', label:'Cargas voluminosas',},
			{value: '7', label:'Cargas de dimensiones especiales',},
			{value: '8', label:'Cargas frágiles',},
			{value: '9', label:'Cargas ligeras',},
			{value: '10', label:'Carga perecedera',},
			{value: '11', label:'Carga imperecedera',},
			{value: '12', label:'Cargas resistentes',},
		],
		incoterms: [
			{ value:'EXW', label: 'Ex-Works', },
			{ value:'FCA', label: 'Free Carrier', },
			{ value:'CPT', label: 'Carriage Paid To', },
			{ value:'CIP', label: 'Carriage and Insurance Paid To', },
			{ value:'DAP', label: 'Delivered At Place', },
			{ value:'DPU', label: 'Delivered at Place Unloaded', },
			{ value:'DDP', label: 'Delivered Duty Paid', },
			{ value:'FAS', label: 'Free Alongside Ship', },
			{ value:'FOB', label: 'Free On Board', },
			{ value:'CFR', label: 'Cost and Freight', },
			{ value:'CIF', label: 'Cost, Insurance, and Freight', },
		],
		priority: [
			{ value: 'FRAGIL', label: 'Fragil', },
			{ value: 'URGENTE', label: 'Entrega Urgente', },
			{ value: 'SEGURO', label: 'Seguro', },
			{ value: 'EMBALAJE', label: 'Embalaje', },
		],
	},
}
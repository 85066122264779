

export const ImageComponent = (props) => {
	return (
		<figure className={props.className || ''}>
			<img
				src={props.src || '#'} 
				title={props.title || ''}
				alt={props.alt || ''}
			/>
		</figure>
	)
};
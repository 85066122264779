import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import environment from 'src/environment';
import {  
  // RouterProvider,
  // createHashRouter,
  // BrowserRouter,
  HashRouter,
} from "react-router-dom";
import {disableReactDevTools} from '@fvilers/disable-react-devtools';

if (!environment.debug) {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
// const router = createHashRouter([
//   {
//     path: "*",
//     element: <App />,
//   }
// ])
root.render(
  <React.StrictMode>
    {/* <RouterProvider router={router}></RouterProvider> */ }
    <HashRouter hashType="noslash">
      <App />  
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import { homeSettings } from 'src/constants';
import {
	Article,
} from 'src/components';
import 'src/containers/body/about/AboutContainer.scss';

const about = homeSettings.about || {};

const AboutMissionBox = (props) => {
	return (
		<div className="about-mission">
			<div
				className="box-image"
				style={{backgroundImage: `url(${about.mission.image.src})`}} >
			</div>
			<Article
				className="box-content"
				title="NOSOTROS"
				subtitle={about.mission.title}
				value={about.mission.value}
				svg={about.mission.icon.svg}
			/>
		</div>
	)
};

const AboutVisionBox = (props) => {
	return (
		<div className="about-vision">
			<Article
				className="box-content"
				subtitle={about.vision.title}
				value={about.vision.value}
				svg={about.vision.icon.svg}
			/>
			<div
				className="box-image"
				style={{backgroundImage: `url(${about.vision.image.src})`}} >
			</div>
		</div>
	)
};

export const AboutContainer = (props) => {
	return (
		<div className="about-layer">
			<AboutMissionBox />
			<AboutVisionBox />
		</div>
	)
};

import { FontIcon } from 'src/components';
import { getRandomNumber } from 'src/helpers/utils';
import { homeSettings } from 'src/constants';
import 'src/containers/footer/FooterContainer.scss';


const footer = homeSettings.footer || {};

const FooterContactSocial = (props) => {
	return (<div className="footer-contact-social">
		<label>Síguenos en: </label>
		<span>
			<a href="/"><FontIcon
				icon="fa-brands fa-twitter"
				href={footer.social.twitter}
				/></a>
			<a href="/"><FontIcon
				icon="fa-brands fa-facebook-f"
				href={footer.social.facebook}
				/></a>
			<a href="/"><FontIcon
				icon="fa-brands fa-instagram"
				href={footer.social.instagram}
				/></a>
			<a href="/"><FontIcon
				icon="fa-brands fa-youtube"
				href={footer.social.youtube}
				/></a>
		</span>
	</div>)
};

const FooterContactLabel = (props) => {
	return (<div className="footer-contact-labels">
		<article>
			<h2>CONTACTO</h2>
			{footer.labels? footer.labels.map((x) => {
				return (
					<p key={getRandomNumber()}><b>{x.label}: </b> {x.value}</p>
				)
			}):null}
		</article>
	</div>)
};

const FooterContact = (props) => {
	return (
		<div className="footer-contact">
			<FooterContactSocial />
			<FooterContactLabel />
		</div>
	)
};

const FooterCopyright = (props) => {
	return (
		<div className="footer-copyright">
			<span>
				<label>
					{footer.copyright}
				</label>
			</span>
		</div>
	)
};

export function FooterContainer(props) {
	return (
		<div className="footer-layer">
			<FooterContact />
			<FooterCopyright />
		</div>
	)
};



export const navigatorList = [
	{
		key: 1,
		description: "Nosotros",
		className: "navigator-item",
		onClick: null,
		path: '/about',
		name: 'about',
		componentClass: 'about-layer',
	},
	{
		key: 2,
		description: "Servicios",
		className: "navigator-item",
		onClick: null,
		path: '/services/ocean',
		name: 'services',
		componentClass: 'service-layer',
	},
	{
		key: 3,
		description: "Tarifa",
		className: "navigator-item",
		onClick: null,
		selected: false,
		path: '/rates',
		name: 'rates',
		componentClass: 'rates-layer',
	},
	{
		key: 4,
		description: "Cotizar",
		className: "navigator-item",
		onClick: null,
		path: '/operations/proform',
		name: 'proform',
		componentClass: 'operations-layer',
	},
	{
		key: 5,
		description: "Tracking",
		className: "navigator-item",
		onClick: null,
		path: '/operations/tracking',
		name: 'tracking',
		componentClass: 'operations-layer',
	},
	{
		key: 6,
		description: "Contacto",
		className: "navigator-item",
		onClick: null,
		path: '/contact',
		name: 'contact',
		componentClass: 'contact-layer',
	},
];

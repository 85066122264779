

import {
	useEffect,
	useState,
} from 'react';
import {
	useForm,
} from 'react-hook-form';

import { getRandomNumber } from 'src/helpers/utils';
import {
	homeSettings,
	regexValidators,
} from 'src/constants';
import {
	Button,
	FormInput,
	FormErrorLabel,
} from 'src/components';
import 'src/containers/body/contact/ContactContainer.scss';

const contact = homeSettings.contact || {};
const defaultValues = {
	name: '',
	lastname: '',
	phone: '',
	email: '',
	company: '',
	message: '',
};

const ContactLabelItem = (props) => {
	const item = props.item || {};
	const IconComponent = item.image.svg;
	return (
		<div className="label-item">
			<IconComponent />
			<article>
				<h3>{item.label}</h3>
				<p>{item.value}</p>
			</article>
		</div>
	)
};

const ContactLabels = (props) => {
	return (<div className="contact-labels">
		{ contact.labels? contact.labels.map((item) => {
			return (
				<ContactLabelItem
					key={getRandomNumber()}
					item={item}
				/>
			)
		}):null}
	</div>)
};

const ContactFieldSet = (props) => {
	const [submittedData, setSubmittedData] = useState({});
	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		formState: {errors, isSubmitSuccessful,},
	} = useForm({defaultValues, mode: 'onChange'});

	useEffect(()=> {
		if (formState.isSubmitSuccessful) {
      		reset({...defaultValues});
    	}
	}, [formState, submittedData, reset]);

	const onSubmit = async (data)=> {
		if (props.isLoading) return ;

		setSubmittedData(data);
		console.log("submiting data");
		// const mailTemplate = selectorMailingTemplateByKey(
		// 	props.templates, props.settings.templateKey
		// )
		// const payload = {
		// 	payload: data,
		// 	template: mailTemplate.uuid
		// }
		// const outcome = await props.dispatch(postMailing(payload)); // eslint-disable-line
	};

	return (
		<form>
			<div className="form-item-name">
				<FormInput
					name="name"
					control={control}
					placeholder="Nombre"
					rules={{
						required:true,
						maxLength: {value: 25, message: 'Caracteres permitidos: 25'},
						pattern: {
							value: regexValidators.personName,
							message: 'Sólo se permite letras.'
						},
					}}
					defaultValue=""
				/>
				{errors.name && <FormErrorLabel message={errors.name?.message}/>}
			</div>
			<div className="form-item-lastname">
				<FormInput
					name="lastname"
					control={control}
					placeholder="Apellidos"
					rules={{
						required:true,
						maxLength: {value: 25, message: 'Caracteres permitidos: 25'},
						pattern: {
							value: regexValidators.personName,
							message: 'Sólo se permite letras.'
						},
					}}
					defaultValue=""
				/>
				{errors.lastname && <FormErrorLabel message={errors.lastname?.message}/>}
			</div>
			<div className="form-item-phone">
				<FormInput
					type="number"
					name="phone"
					control={control}
					placeholder="Teléfono"
					rules={{
						required:true,
						minLength: {value: 7, message: 'Caracteres mínimos: 7'},
						maxLength: {value: 12, message: 'Caracteres permitidos: 12'},
						pattern: {
							value: regexValidators.phoneNumber,
							message: 'Sólo se permite números.'
						},
					}}
					defaultValue=""
				/>
				{errors.phone && <FormErrorLabel message={errors.phone?.message}/>}
			</div>
			<div className="form-item-email">
				<FormInput
					type="email"
					name="email"
					control={control}
					placeholder="Email"
					rules={{
						required:true,
						maxLength: {value: 50, message: 'Caracteres permitidos: 50'},
						pattern: {
							value: regexValidators.emailAddress,
							message: 'Formato invalido',
						}
					}}
					defaultValue=""
				/>
				{errors.email && <FormErrorLabel message={errors.email?.message}/>}
			</div>
			<div className="form-item">
				<FormInput
					name="company"
					control={control}
					placeholder="Empresa"
					rules={{
						required:true,
						maxLength: {value: 50, message: 'Caracteres permitidos: 50'},
						pattern: {
							value: regexValidators.companyName,
							message: 'Sólo se permite números y letras.'
						},
					}}
					defaultValue=""
				/>
				{errors.company && <FormErrorLabel message={errors.company?.message}/>}
			</div>
			<div className="form-item">
				<FormInput
					type="textarea"
					name="message"
					control={control}
					placeholder="Mensaje"
					rules={{
						required:true,
						minLength: {value: 70, message: 'Caracteres mínimos: 70'},
						maxLength: {value: 250, message: 'Caracteres permitidos: 250'},
					}}
				/>
				{errors.message && <FormErrorLabel message={errors.message?.message}/>}
			</div>
			<Button
				className={'contact-button' + (props.isLoading? ' disabled': '')}
				onClick={handleSubmit(onSubmit)}
				text={props.isLoading ?'Procesando': 'Enviar'}
			/>
		</form>
	)
};

const ContactForm = (props) => {
	return (<div className="contact-form">
		<article>
			<h2>CONTÁCTANOS</h2>
			<p>{contact.form.value}</p>
			<ContactFieldSet loading={false}/>
		</article>
	</div>)
};

export const ContactContainer = (props) => {
	return (
		<div className="contact-layer">
			<ContactForm />
			<ContactLabels />
		</div>
	)
};
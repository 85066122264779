
// import { useNavigate } from 'react-router-dom';
import {
	Button,
} from 'src/components';
import 'src/containers/claim/ClaimBookContainer.scss';
import {homeSettings} from 'src/constants';

const book = homeSettings.claim.book || {};

const ClaimBookContent = (props) => {
	const IconComponent = book.title.svg;
	return (
		<div className="book-content">
			<article>
				<div className="book-title">
					<IconComponent />
					<h2>LIBRO DE RECLAMACIONES</h2>
				</div>
				<p className="normal-sentence">¿Tienes alguna queja o reclamo?</p>
				<p className="stress-sentence">!Estamos para ayudarte!</p>
				<Button
					className="book-button"
					text="Ingresa Aquí"
					onClick={props.claimButtonClick}
					href='#/claim'
					/>
			</article>
		</div>
	)
};

const ClaimBookImage = (props) => {
	return (
		<div className="book-image"
			style={{ backgroundImage: `url(${book.image.src})` }} >
		</div>
	)
};

export const ClaimBookContainer = (props) => {
	return (
		<div className="claim-book-layer">
			<ClaimBookImage />
			<ClaimBookContent />
		</div>
	)
};

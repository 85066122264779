
import {
  useRoutes,
} from 'react-router-dom';

import {
	serviceConfig,
	operationConfig,
} from 'src/constants';
import { ServicesTab } from 'src/containers/body/services/ServicesContainer'; 
import { ProformContainer } from 'src/containers/body/operations/ProformContainer';
import { TrackingContainer } from 'src/containers/body/operations/TrackingContainer';
import { MainRouter } from 'src/routers/main/MainRouter';
import { ClaimRouter } from 'src/routers/claim/ClaimRouter';

const defaultPaths = () => {
	const paths = [
	 '/', 'about', 'rates', 'proform', 'tracking', 'contact',
	];

	return paths.map((path)=> {
		return {
			path: `${path}`,
			element: <MainRouter />,
		}
	})
};

const servicesChildren = () => {
	return serviceConfig.items.map(item => {
		return {
			path: item.name,
			element: <ServicesTab name={item.name}/>,
		}
	})
};

const operationsChildren = () => {
	const componentViews = {
		tracking: TrackingContainer,
		proform: ProformContainer,
	}
	return operationConfig?.items.map( item => {
		const Element = componentViews[item.name] || null;
		return {
			path: item.name,
			element: <Element />
		}
	})
}

export const BodyRouters = () => {
  const element = useRoutes([
    ...defaultPaths(),
		{
			path: "services",
			element: <MainRouter />,
			children: [
				...servicesChildren()
			],
		},
		{
			path: "operations",
			element: <MainRouter />,
			children: [
				...operationsChildren()
			],
		},
    {
      path: "claim",
      element: <ClaimRouter />,
    },
  ])
  return element
};

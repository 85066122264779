
import {
	Controller,
} from 'react-hook-form';
import Select from 'react-select';
import {FontIcon} from 'src/components';

export const FormErrorLabelComponent = (props) => {
	return (
		<span className="error">
			<FontIcon
				className='form-icon'
				icon='fa-solid fa-triangle-exclamation'>
			</FontIcon>
			{ props.message? props.message : 'Valor Invalido'}
		</span>
	)
};

export const FormInputComponent = (props) => {

	const inputTextRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="text"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
			autoComplete={props.autocomplete || "off"}
		/>
	);

	const textAreaRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<textarea
			className="control-textarea"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
			rows={props.rows}
			cols={props.columns}
		/>
	);

	const inputNumberRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="number"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
			autoComplete={props.autocomplete || "off"}
		/>
	);

	const inputEmailRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<input
			className="control-text"
			type="email"
			ref={ref}
			onBlur={onBlur}
			onChange={onChange}
			value={value}
			name={name}
			placeholder={props.placeholder}
			autoComplete={props.autocomplete || "off"}
		/>
	);

	const inputRadioRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<label>
			<input
				name={name}
				value={props.value}
				type="radio"
				checked={props.value === value ? true: false}
				ref={ref}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<span>{props.label}</span>
		</label>
	);

	const SelectRender = ({field: {onChange, onBlur, value, name, ref}}) => (
		<Select
			name={name}
			onChange={onChange}
			onBlur={onBlur}
			inputRef={ref}
			value={value}
			options={props.options}
			isSearchable={props.isSearchable}
			isClearable={props.isClearable}
			placeholder={props.placeholder}
		/>
	)

	return (
		<Controller
			defaultValue={props.defaultValue}
			control={props.control}
			name={props.name}
			rules={props.rules}
			render={ props.type === 'textarea' ? textAreaRender:
						props.type === 'number' ? inputNumberRender :
						props.type === 'email' ? inputEmailRender :
						props.type === 'radio' ? inputRadioRender:
						props.type === 'select' ? SelectRender: inputTextRender }
		/>
	)
};

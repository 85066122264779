import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'src/helpers';
import { FontIcon } from 'src/components';

import 'src/components/NavigatorComponent.scss';

export function NavigatorSandwichComponent(props) {
	return (
		<nav className={props.className ? props.className : 'header-navigator-sandwich'}>
			<div className="navigator-item">
				<NavLink to="" onClick={props.onClick}>
					<FontIcon icon="fa-solid fa-bars"></FontIcon>
				</NavLink>
			</div>
		</nav>
	)	
};

function NavigatorItemComponent(props){
	const items = props.items;
	return (
		<div
			className="navigator-item">
			{items.length? items.map(item=> {
				const SvgComponent = item.image?.svg;
				return (
					<NavLink
						id={item.id}
						key={item.key}
						to={item.path}
						onClick={item.onClick}
						>
						{item.image?.svg? <SvgComponent />:null}
						{item.description}
					</NavLink>
				)
			}):null}
		</div>
	)	
};

export function NavigatorListComponent(props) {
	const items = props.items;
	var className = props.className ? props.className : 'header-navigator';
	className += !props.sandwich ? ' hide': '';
	return (
		<nav
			ref={props.reference||null}
			className={className}>
			<NavigatorItemComponent items={items}>
			</NavigatorItemComponent>
		</nav>
	)	
};

export const NavigatorComponent = withRouter(NavigatorListComponent);


const environment = {
	authCredentials: {
		username: process.env.REACT_APP_AUTH_USERNAME,
		password: process.env.REACT_APP_AUTH_PASSWORD,
		refreshToken: process.env.REACT_APP_AUTH_REFRESH_TOKEN,
	},
	apiUrl: process.env.REACT_APP_API_URL,
	debug: (process.env.REACT_APP_DEBUG === "false" ?false:true),
};

export default environment;


import {
	useState,
	useEffect,
} from 'react';
import {
	useForm,
} from 'react-hook-form';
import {
	Button,
	FormInput,
	FormErrorLabel,
} from 'src/components';
import { homeSettings } from 'src/constants';
import 'src/containers/claim/ClaimContainer.scss';

const defaultValues = {
	message: '',
	claimType: '',
	contractedGood: '',
};

const claimConfig = homeSettings.claim?.form || {};

const ClaimForm = (props) => {
	const [submittedData, setSubmittedData] = useState({});
	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		formState: {errors, isSubmitSuccessful,}
	} = useForm({
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	useEffect(()=> {
		if (formState.isSubmitSuccessful) {
      		reset({...defaultValues});
    	}
	}, [formState, submittedData, reset]);

	const onSubmit = async (data)=> {
		if (props.isLoading) return ;

		setSubmittedData(data);
		console.log("submiting data:", data);

		// const mailTemplate = selectorMailingTemplateByKey(
		// 	props.templates, props.settings.templateKey
		// )
		// const payload = {
		// 	payload: data,
		// 	template: mailTemplate.uuid
		// }
		// const outcome = await props.dispatch(postMailing(payload)); // eslint-disable-line
	};

	return (
		<form className="claim-form">
			<h3>DETALLE DE LA RECLAMACIÓN</h3>
			<div className="form-item-radio">
				<FormInput 
					type="radio"
					value="complaint"
					control={control}
					name="claimType"
					label="Queja"
					rules={{required: true,}}
				/>
			</div>
			<div className="form-item-radio">
				<FormInput
					type="radio"
					value="claim"
					control={control}
					name="claimType"
					label="Reclamo"
					rules={{required: true,}}
				/>
				{errors.claimType && 
						<FormErrorLabel message={errors.claimType?.message}/>}
			</div>
			<h3>IDENTIFICACIÓN DEL BIEN CONTRATADO</h3>
			<div className="form-item-radio">
				<FormInput 
					type="radio"
					value="product"
					control={control}
					name="contractedGood"
					label="Producto"
					rules={{required: true,}}
				/>
			</div>
			<div className="form-item-radio">
				<FormInput
					type="radio"
					value="service"
					control={control}
					name="contractedGood"
					label="Servicio"
					rules={{required: true,}}
				/>
				{errors.contractedGood && 
						<FormErrorLabel message={errors.contractedGood?.message}/>}
			</div>
			<div className="form-item">
				<FormInput
					type="textarea"
					name="message"
					control={control}
					placeholder="Detalle de reclamo"
					rows="5"
					rules={{
						required:true,
						minLength: {value: 70, message: 'Caracteres mínimos: 70'},
						maxLength: {value: 250, message: 'Caracteres permitidos: 250'},
					}}
				/>
				{errors.message && 
					<FormErrorLabel message={errors.message?.message}/>}
			</div>
			<Button
				className={'claim-button' + (props.isLoading? ' disabled': '')}
				onClick={handleSubmit(onSubmit)}
				text={props.isLoading ?'Procesando': 'Enviar'}
			/>
		</form>
	)	
};

const ClaimImage = (props) => {
	return (
		<div className="claim-image"
			style={{backgroundImage: `url(${claimConfig.image.src})`}}>
		</div>
	)	
};

export const ClaimContainer = (props) => {
	const SvgComponent = claimConfig.title.svg;
	return (
		<div className="claim-layer">
			<article className="content">
				<h2>
					<SvgComponent />
					<label>
						LIBRO DE RECLAMACIONES
					</label>
				</h2>
				<ClaimImage />
				<ClaimForm />
			</article>
		</div>
	)
};

import {Image} from 'src/components';

export const ArticleComponent = (props) => {
	const SvgComponent = props.svg || null;
	return (<article className={props.className || ''}>
		{props.title ? <h2>{props.title}</h2> : null}
		<span>
			{ props.image ? <Image src={props.image} />:null }
			{ props.svg ? <SvgComponent />:null }
			<h3>{props.subtitle}</h3>
		</span>
		<p>{props.value}</p>
		{props.children ? props.children: null}
	</article>)
};


import {
	useState,
	useEffect,
} from 'react';
import {
	useForm,
} from 'react-hook-form';
import {
	Button,
	FormInput,
	FormErrorLabel,
} from 'src/components';
import { homeSettings } from 'src/constants';
import { getRandomNumber } from 'src/helpers/utils';
import 'src/containers/body/operations/ProformContainer.scss';

const defaultValues = {
	fullName: '',
	email: '',
	phone: '',
	tipoCarga: '',
	origin: '',
	destiny: '',
	incoterm: '',
	width: '',
	height: '',
	weight: '',
	size: '',
	priority: '',
};

const operations = homeSettings.operations || {}
const options = operations?.list || {}

export const ProformContainer = (props) => {
	const [submittedData, setSubmittedData] = useState({});
	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		formState: {errors, isSubmitSuccessful,}
	} = useForm({
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	useEffect(()=> {
		if (formState.isSubmitSuccessful) {
      		reset({...defaultValues});
    	}
	}, [formState, submittedData, reset]);

	const onSubmit = async (data)=> {
		if (props.isLoading) return ;

		setSubmittedData(data);
		console.log("submiting data:", data);

		// const mailTemplate = selectorMailingTemplateByKey(
		// 	props.templates, props.settings.templateKey
		// )
		// const payload = {
		// 	payload: data,
		// 	template: mailTemplate.uuid
		// }
		// const outcome = await props.dispatch(postMailing(payload)); // eslint-disable-line
	};

	return (
		<div className="proform-tab">
			<h3>INFORMACIÓN PERSONAL</h3>
			<div className="form-item-name">
				<FormInput
					type="text"
					name="fullName"
					control={control}
					placeholder="Nombre Completo"
					rules={{
						required:true,
						minLength: {value: 10, message: 'Caracteres mínimos: 10'},
						maxLength: {value: 50, message: 'Caracteres permitidos: 50'},
					}}
				/>
				{errors.fullName && 
					<FormErrorLabel message={errors.fullName?.message}/>}
			</div>
			<div className="form-item-email">
				<FormInput
					type="text"
					name="email"
					control={control}
					placeholder="Correo Electrónico"
					rules={{
						required:true,
					}}
				/>
				{errors.email && 
					<FormErrorLabel message={errors.email?.message}/>}
			</div>
			<div className="form-item-phone">
				<FormInput
					type="text"
					name="phone"
					control={control}
					placeholder="Teléfono"
					rules={{
						required:true,
					}}
				/>
				{errors.phone && 
					<FormErrorLabel message={errors.phone?.message}/>}
			</div>
			<h3>DATOS DE ENVÍO</h3>
			<div className="form-item-load-type">
				<FormInput 
					type="select"
					control={control}
					name="tipoCarga"
					rules={{required: true}}
					options={options.tipoCarga}
					isClearable={true}
					isSearchable={true}
					placeholder="Tipo de Carga"
				/>
				{errors.tipoCarga && 
					<FormErrorLabel message={errors.tipoCarga?.message}/>}
			</div>
			<div className="form-item-origin">
				<FormInput
					type="text"
					name="origin"
					control={control}
					placeholder="Lugar de salida"
					rules={{
						required:true,
					}}
				/>
				{errors.origin && 
					<FormErrorLabel message={errors.origin?.message}/>}
			</div>
			<div className="form-item-destiny">
				<FormInput
					type="text"
					name="destiny"
					control={control}
					placeholder="Lugar de entrega"
					rules={{
						required:true,
					}}
				/>
				{errors.destiny && 
					<FormErrorLabel message={errors.destiny?.message}/>}
			</div>
			<div className="form-item-incoterm">
				<FormInput 
					type="select"
					control={control}
					name="incoterm"
					rules={{required: true}}
					options={options?.incoterms ? options.incoterms.map(item=> {
						return { ...item, label: `${item.value} - ${item.label}`}
					}): []}
					isClearable={true}
					isSearchable={true}
					placeholder="Incoterms"
				/>
				{errors.incoterm && 
					<FormErrorLabel message={errors.incoterm?.message}/>}
			</div>
			<div className="form-item-dimensions">
				<div className="form-item-width">
					<FormInput
						type="text"
						name="width"
						control={control}
						placeholder="Ancho"
						rules={{
							required:true,
						}}
					/>
					{errors.width && 
						<FormErrorLabel message={errors.width?.message}/>}
				</div>
				<div className="form-item-height">
					<FormInput
						type="text"
						name="height"
						control={control}
						placeholder="Altura"
						rules={{
							required:true,
						}}
					/>
					{errors.height && 
						<FormErrorLabel message={errors.height?.message}/>}
				</div>
			</div>
			<div className="form-item-dimensions">
				<div className="form-item-weight">
					<FormInput
						type="text"
						name="weight"
						control={control}
						placeholder="Peso"
						rules={{
							required:true,
						}}
					/>
					{errors.weight && 
						<FormErrorLabel message={errors.weight?.message}/>}
				</div>
				<div className="form-item-size">
					<FormInput
						type="text"
						name="size"
						control={control}
						placeholder="Tamaño"
						rules={{
							required:true,
						}}
					/>
					{errors.size && 
						<FormErrorLabel message={errors.size?.message}/>}
				</div>
			</div>
			<div className="form-radio-buttons">
				{ options?.priority ? options.priority.map( item => {
					return (
						<div
							key={getRandomNumber()}
							className="form-item-radio">
							<FormInput 
								type="radio"
								value={item.value}
								control={control}
								name="priority"
								label={item.label}
								rules={{required: true,}}
							/>
						</div>
					)
				}):null}
				{errors.priority &&  <FormErrorLabel message={errors.priority?.message}/>}
			</div>

			<Button
				className={'proform-button' + (props.isLoading? ' disabled': '')}
				onClick={handleSubmit(onSubmit)}
				text={props.isLoading ?'Procesando': 'Solicitar Una Cotización'}
			/>
		</div>
	)
};
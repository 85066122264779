import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Navigator,
	NavigatorSandwich,
	FontIcon,
} from 'src/components';
import {
	navigatorList,
	homeSettings,
} from 'src/constants';
import {sleep} from 'src/helpers';
import 'src/containers/header/HeaderContainer.scss';


const HeaderLogo = (props) => {
	return (
		<div className="header-logo"></div>
	)
};

const HeaderInfoBoxIcon = (props) => {
	return (
		<span>			
			{ props.icon ? <a href={props.href || '#'}>
				<FontIcon icon={props.icon}></FontIcon>
				{ props.label ? <label> {props.label} </label> : null}
			</a> : null}
		</span>
	)
};

const HeaderInfoBox = (props) => {
	return (
		<div className={props.className || ''}>
			<HeaderInfoBoxIcon 
				icon={props.icon}
				label={props.label}
				href={props.href}
			/>
		</div>
	)
};

const HeaderInfo = (props) => {
	const tags = homeSettings.tags || {} ;
	return (
		<div className="header-info">
			<HeaderInfoBox
				className="info-email"
				label={tags.email.label}
				href={tags.email.href}
				icon="fa-solid fa-envelope"
				/>
			<HeaderInfoBox
				className="info-phone"
				label={tags.phone.label}
				href={tags.phone.href}
				icon="fa-solid fa-phone"
				/>
			<div className="info-social">
				<HeaderInfoBoxIcon icon="fa-brands fa-twitter"
					href={tags.twitter.href}
					/>
				<HeaderInfoBoxIcon icon="fa-brands fa-facebook-f"
					href={tags.facebook.href}
					/>
				<HeaderInfoBoxIcon icon="fa-brands fa-instagram"
					href={tags.instagram.href}
					/>
				<HeaderInfoBoxIcon icon="fa-brands fa-youtube"
					href={tags.youtube.href}
					/>
			</div>
		</div>
	)
};

export function HeaderContainer(props) {
	const [sandwich, setSandwich] = useState(true);
	const navigate = useNavigate();
	const sandwichOnClick = (e) => {
		e.preventDefault();
		setSandwich(oldState => oldState ? false: true)
	};
	
	const items = navigatorList.map(item => {
		return {
			...item,
			onClick: async (e) => {
				e.preventDefault();
				navigate(item.path);
				await sleep(200);
				const element = document.getElementsByClassName(item.componentClass);
				if (element.length) {
					element[0].scrollIntoView();
				}
			}
		}
	})
	return (
		<div className="header-layer">
			<HeaderInfo></HeaderInfo>
			<HeaderLogo></HeaderLogo>
			<NavigatorSandwich onClick={sandwichOnClick}/>
			<Navigator
				className="header-navigator"
				sandwich={sandwich}
				items={items} />
		</div>
	)
};

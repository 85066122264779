

import {
	createStore,
	combineReducers,
	applyMiddleware,
	compose,
} from 'redux';
import thunk from 'redux-thunk';
// import proformReducer from './proform/proformReducers';
// import mailingTemplateReducer from './mailing/mailingReducers';
import authReducers from './auth/authReducers';
import environment from 'src/environment';

// const rootReducer = (state = {}, action) => {
// 	return {}
// };

const rootReducer = combineReducers({
	// proform: proformReducer,
	// mailing: mailingTemplateReducer,
	auth: authReducers,
})

// enable devTools for dev environment
const composeEnhancer = (environment?.debug? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;
const store = createStore(
	rootReducer,
	composeEnhancer(applyMiddleware(thunk)),
);

export default store ;



import { ReactComponent as ServicesOceanImage } from 'src/media/images/services-ocean-icon.svg';
import { ReactComponent as ServicesAirImage } from 'src/media/images/services-air-icon.svg';
import { ReactComponent as ServicesLandImage } from 'src/media/images/services-land-icon.svg';
import { ReactComponent as ServicesCourierImage } from 'src/media/images/services-courier-icon.svg';
import { ReactComponent as ServicesAduanasImage } from 'src/media/images/services-aduanas-icon.svg';

const companyName = "Multilogístico Perú Cargo";
export const serviceConfig = {
	items: [
		{
			path: '/services/ocean',
			name: 'ocean',
			description: 'Transporte Marítimo',
			image: {svg: ServicesOceanImage, },
			body: {
				top: `${companyName} cuenta con ofertas de fletes desde Puerto de Callao, Lima - Perú. Gozamos de excelentes relaciones de trabajo con las principales líneas marítimas como también con los NVOCC que operan desde Callao, Perú. Nuestro servicio de transporte de carga marítima ofrece lo siguiente:`,
				middleType: 'list',
				middle: [
					'FCL / Llenado y estiba de contenedores.',
					'LCL Carga Consolidada.',
					'OOG / Carga sobredimensionada.',
					'Carga Refrigerada / Perecibles.',
				],
				footer: 'Debido al alto volumen de cargas embarcadas, gozamos de las tarifas del mercado.',
			}
		},
		{
			path: '/services/air',
			name: 'air',
			description: 'Transporte Aéreo',
			image: {svg: ServicesAirImage, },
			body: {
				top: `${companyName} ha desarrollado fuertes laxos y una excelente relación de trabajo con una amplia variedad de compañías aéreas que operan en Perú.`,
				middleType: 'text',
				middle: [
					`Esto nos permite obtener excelentes tarifas y ofrecer un buen servicio integral. Sin importar el tamaño, tipo o valor de la carga a ser embarcada, ${companyName} le ayudará a evaluar la opción más eficiente, segura y económica para que realice su envío.`
				],
				footer: `Debido a nuestros altos volúmenes. ${companyName} puede ofrecer descuentos Spot Tarifa por cargas de gran tamaño o embarcadores frecuentes.`,
			}
		},
		{
			path: '/services/land',
			name: 'land',
			description: 'Transporte Terrestre',
			image: {svg: ServicesLandImage, },
			body: {
				top: `${companyName} realiza transportes de carga local, tanto para importación como exportaciones, brindándole seguridad y a bajo costo para cualquier tipo de carga.`,
				middleType: 'text',
				middle: [],
				footer: null,
			}
		},
		{
			path: '/services/courier',
			name: 'courier',
			description: 'Servicios de Courier',
			image: {svg: ServicesCourierImage, },
			body: {
				top: `${companyName} ofrece entrega rápida y de bajo costo de documentos y paquetes a travéz de nuestro servicio de mensajeria en todo el mundo. Enviamos documentos y paquetes de tiempo sensible a nivel Local, nacional e internacional. Nuestro servicio de mensajería es especialmente rentable en comparación con el flete aéreo cuando envía paquetes de bajo peso / volumen como muestras a nuevos clientes y proveedores. Negociamos y mejoramos constantemente nuestras tarifas con empresas multinacionales de mensajería, lo que nos permite aprovechar los contratos de volumen que nos permiten ofrecer bajos costos a todos nuestros clientes, independientes del volumen que envíe.`,
				middleType: 'text',
				middle: [
					'No dude en ponerse en contacto con nuestro equipo de ventas para discutir sus necesidades y ver si podemos reducir sus costos al tiempo que aumenta la eficiencia.',
				],
				footer: null,
			}
		},
		{
			path: '/services/aduanas',
			name: 'aduanas',
			description: 'Servicios de Aduanas',
			image: {svg: ServicesAduanasImage, },
			body: {
				top: `Nos encargamos de todo el proceso de verificación de documentos, trámites y asesoría para que desaduane su carga con rapidez.`,
				middleType: 'text',
				middle: [
					'Adicionalmente nuestra red de agentes alrededor del mundo, asegura que su embarque sea recibido y manejando con el debido cuidado y diligencia al llegar a su lugar de destino.',
				],
				footer: null,
			}
		},
	]
};

import {
  Provider,
  connect,
} from 'react-redux' ;
import store from 'src/redux/store';
import {
  selectAccessToken,
} from 'src/redux/auth/authReducers';
import {
  Header,
  Footer,
} from 'src/containers';
import { BodyRouters } from 'src/routers';
import 'src/App.css';
import 'src/media/styles/media-query.scss';

// store.dispatch(fetchAuthToken())

const AppContentComponent = (props) => {
  return (
    props.isAppAuthenticated? <div className="main-layer">
      <Header></Header>
      <BodyRouters />
      <Footer></Footer>
    </div> : null
  )
};

const mapStateToProps = (state) => {
  return {
    isAppAuthenticated: selectAccessToken(state),
  }
};

const AppContent = connect(mapStateToProps)(AppContentComponent);

function App() {
  return (
    <Provider store={store}>
       <AppContent></AppContent>
    </Provider>
  )
};

export default App;


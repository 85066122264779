

import {
	useState,
	useEffect,
} from 'react';
import {
	useForm,
} from 'react-hook-form';
import {
	Button,
	FormInput,
	FormErrorLabel,
} from 'src/components';
import { getRandomNumber } from 'src/helpers/utils';
import { homeSettings } from 'src/constants';
import 'src/containers/body/operations/TrackingContainer.scss';

const operations = homeSettings.operations || {}
const options = operations.list || {}
const defaultValues = {
	regimen: '',
	trackingNumber: '',
	via: '',
};


export const TrackingContainer = (props) => {
	const [submittedData, setSubmittedData] = useState({});
	const {
		handleSubmit,
		control,
		reset,
		formState,
		watch,
		formState: {errors, isSubmitSuccessful,}
	} = useForm({
		defaultValues: defaultValues,
		mode: 'onChange',
	});

	useEffect(()=> {
		if (formState.isSubmitSuccessful) {
      		reset({...defaultValues});
    	}
	}, [formState, submittedData, reset]);

	const onSubmit = async (data)=> {
		if (props.isLoading) return ;

		setSubmittedData(data);
		console.log("submiting data:", data);

		// const mailTemplate = selectorMailingTemplateByKey(
		// 	props.templates, props.settings.templateKey
		// )
		// const payload = {
		// 	payload: data,
		// 	template: mailTemplate.uuid
		// }
		// const outcome = await props.dispatch(postMailing(payload)); // eslint-disable-line
	};

	return (
		<div className="tracking-tab">
			<h3>TIPO DE ENVÍO</h3>
			<div className="form-item">
				<FormInput
					type="select"
					name="regimen"
					control={control}
					placeholder="Tipo de Envío"
					rules={{
						required:true,
					}}
					options={options.regimen}
					isClearable={true}
					isSearchable={true}
				/>
				{errors.regimen && 
					<FormErrorLabel message={errors.regimen?.message}/>}
			</div>
			<h3>TRACKING</h3>
			<div className="form-item">
				<FormInput
					type="text"
					name="trackingNumber"
					control={control}
					placeholder="Número de seguimiento"
					rules={{
						required:true,
					}}
				/>
				{errors.trackingNumber && 
					<FormErrorLabel message={errors.trackingNumber?.message}/>}
			</div>

			<div className="form-radio-buttons">
				{ options.via? options.via.map( item => {
					return (
						<div
							key={getRandomNumber()}
							className="form-item-radio">
							<FormInput 
								type="radio"
								value={item.value}
								control={control}
								name="via"
								label={item.label}
								rules={{required: true,}}
							/>
						</div>
					)}):null}
				{errors.via &&  <FormErrorLabel message={errors.via?.message}/>}
			</div>

			<Button
				className={'tracking-button' + (props.isLoading? ' disabled': '')}
				onClick={handleSubmit(onSubmit)}
				text={props.isLoading ?'Procesando': 'Rastrear'}
			/>
		</div>
	)
};
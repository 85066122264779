
import {
	Outlet,
	useLocation,
} from 'react-router-dom';
import { getRandomNumber } from 'src/helpers/utils';
import { Navigator } from 'src/components'; 
import { homeSettings } from 'src/constants';
import 'src/containers/body/operations/OperationsContainer.scss';

const operations = homeSettings.operations || {}

export const OperationsContainer = (props) => {
	const location = useLocation();
	const items = operations.items.map( item => {
		return {
			key: getRandomNumber(),
			...item,
		}
	});
	const validatePath = new RegExp("^/operations") ;

	return (<div className="operations-layer">
		<Navigator 
			className="operations-navigator"
			items={items}
		/>
		{ validatePath.test(location.pathname) ? <Outlet />: null }
	</div>)
}
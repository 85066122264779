
import { homeSettings } from 'src/constants';
import {
	Article,
	Button,
} from 'src/components';
import 'src/containers/body/rates/RatesContainer.scss';

const rates = homeSettings.rates || {};

const RatesDownloadBox = (props) => {

	const ratesDownloadClick = (e) => {
		e.preventDefault();
		console.log("download event");
	};

	return (
		<div className="rates-download">
			<Article
				subtitle={rates.download.title}
				svg={rates.download.image.svg}
				value={rates.download.value}
				className="download-content"
				>
				<Button
					className="download-button"
					text="Descargar"
					onClick={ratesDownloadClick} />
			</Article>
		</div>
	)
};

export const RatesContainer = (props) => {
	return (
		<div className="rates-layer"
			style={{backgroundImage: `url(${rates.image.src})`}}
			>
			<RatesDownloadBox />
		</div>
	)
};

import {
	Outlet,
	useLocation,
} from 'react-router-dom';
import { homeSettings } from 'src/constants';
import {getRandomNumber} from 'src/helpers/utils';
import { Navigator } from 'src/components'; 
import 'src/containers/body/services/ServicesContainer.scss';


const services = homeSettings.services || {};

export const ServicesTab = (props) => {
	const instance = services.items.find((x)=> x.name === props.name);
	return (
		<div className="service-tab">
			<div className="tab-title">
				<h2>{instance?.description.toUpperCase()}</h2>
			</div>
			<article className="tab-content">
				{instance.body?.top? <p>{instance.body.top}</p>: null }
				{instance.body?.middle ?
					(instance.body.middleType === 'text'? <p>{instance.body.middle}</p>:
						(instance.body.middleType === 'list'?
						<ul>{instance.body.middle.map(text => {
							return (<li key={getRandomNumber()}>{text}</li>)
						})}</ul>: null)
					):null}
				{instance.body?.footer? <p>{instance.body.footer}</p>: null }
			</article>
		</div>
	)
};

export const ServicesContainer = (props) => {
	const location = useLocation();
	const items = services.items.map( item => {
		return {
			key: getRandomNumber(),
			...item,
		}
	});
	const validatePath = new RegExp("^/services") ;
	return (<div className="service-layer">
		<Navigator 
			className="service-navigator"
			items={items}
		/>
		{ validatePath.test(location.pathname) ? <Outlet /> : null}
	</div>)
};
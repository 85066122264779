
import moment from 'moment';
import {
	decodeJwt,
	decodeJwtExp,
} from 'src/helpers';
import {
	FETCH_AUTH_TOKEN_REQUEST,
	FETCH_AUTH_TOKEN_SUCCESS,
	FETCH_AUTH_TOKEN_FAILURE,
	START_AUTH_HEART_BEAT,
	STOP_AUTH_HEART_BEAT,
} from './authTypes';

const initialState = {
	loading: false,
	tokens: {},
	error: '',
	heartBeat: {
		beating: false,
		instance: null,
	},
}


const authTokenReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_AUTH_TOKEN_REQUEST: return {
			...state,
			loading: true,
		}
		case FETCH_AUTH_TOKEN_SUCCESS: return {
			...state,
			loading: false,
			tokens: action.payload,
			error: '',
		}
		case FETCH_AUTH_TOKEN_FAILURE: return {
			...state,
			loading: false,
			tokens: {},
			error: action.payload,
		}
		case START_AUTH_HEART_BEAT: return {
			...state,
			heartBeat: {
				beating: true,
				instance: action.payload,
			},
		}
		case STOP_AUTH_HEART_BEAT: return {
			...state,
			heartBeat: {
				beating: false,
				instance: null,
			},
		}
		default: return state
	}
}

// export const selectAccessToken = (state) => state.auth.tokens?.access?true:false
export const selectAccessToken = (state) => true
export const selectHeartBeat = (state) => state.auth.heartBeat?.beating
export const selectHeartBeatInstance = (state) => state.auth.heartBeat?.instance
export const selectAuthHeaders = (state) => {
	let data = JSON.parse(localStorage.getItem('jwtifo') || '{}');
	const headers = {
		'Authorization': `Bearer ${data.access}`,
	}
	return {
		headers: headers
	}
}
export const selectDecodedToken = (token) => {
	const tokenDecoded = decodeJwt(token);
	const decodedExp = decodeJwtExp(tokenDecoded.exp);
	const tsExpiration =  moment(decodedExp).unix();
	const tsNow =  moment(new Date()).unix();
	const outcome = new Date((tsExpiration - tsNow) * 1000)
	return {
		hours: outcome.getHours(),
		minutes: outcome.getMinutes(),
		seconds: outcome.getSeconds(),
	}
}

export default authTokenReducer;


import { AboutContainer } from 'src/containers/body/about/AboutContainer';
import { ContactContainer } from 'src/containers/body/contact/ContactContainer';
import { ClaimBookContainer } from 'src/containers/claim/ClaimBookContainer';
import { RatesContainer } from 'src/containers/body/rates/RatesContainer';
import { ServicesContainer } from 'src/containers/body/services/ServicesContainer';
import { OperationsContainer } from 'src/containers/body/operations/OperationsContainer';
import 'src/containers/body/BodyContainer.scss';

export const BodyContainer = (props) => {
	return (
		<div className="body-layer">
			<AboutContainer />
			<ServicesContainer />
			<RatesContainer />
			<OperationsContainer />
			<ClaimBookContainer />
			<ContactContainer />
		</div>
	)
};
